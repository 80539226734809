import React from "react"
import { motion } from "framer-motion"

export default function SwipeMotionDiv(props) {
  const { entry, exit, transitionStatus, children } = props

  return (
    <motion.div
      initial={entry.state}
      animate={
        transitionStatus === "exiting" ? exit.state : { opacity: 1, x: 0, y: 0 }
      }
      transition={
        transitionStatus === "exiting"
          ? { duration: exit.length, ease: "easeIn" }
          : { duration: 0.3, ease: "easeOut" }
      }
    >
      {children}
    </motion.div>
  )
}
