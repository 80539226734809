import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

//gatsby
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

//component
import Layout from "../../components/Layout"
import ThirdLayerHeader from "../../components/ThirdLayerHeader"
import ThirdLayerClose from "../../components/ThirdLayerClose"
import { ThirdLayer, Main } from "../../components/ThirdLayerBody"
import SwipeMotionDiv from "components/ui/SwipeMotionDiv"
import SEO from '../../components/SEO'

const OurCommitment = props => {
  const data = useStaticQuery(query)
  const { location, transitionStatus, entry, exit } = props
  const parentPath = "/" + location.pathname.split("/")[1]

  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.top.pass}`,
          "name": `${data.seo.top.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.page.thirtyYers.pass}`,
          "name": `${data.seo.page.thirtyYers.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": `${data.seo.page.thirtyYers.ourCommitment.pass}`,
          "name": `${data.seo.page.thirtyYers.ourCommitment.title}`,
        }
      }]
    }
  ]

  return (
    <ThirdLayer>
      <Layout>
        <SEO
          title={data.seo.page.thirtyYers.ourCommitment.title}
          pageUrl={data.seo.page.thirtyYers.ourCommitment.pass}
          description={data.seo.page.thirtyYers.ourCommitment.description}
          imgUrl='/mainVisual.jpg'
          type='article'
          jsonld={jsonld}
        />
        <SwipeMotionDiv
          entry={entry}
          exit={exit}
          transitionStatus={transitionStatus}
        >
          <ThirdLayerHeader
            text={data.text.ourCommitment.leadText}
            parentPath={parentPath}
          />
          <Main>
            <p>{data.text.ourCommitment.paragraph1}</p>
            <p>{data.text.ourCommitment.paragraph2}</p>
            <p>{data.text.ourCommitment.paragraph3}</p>
          </Main>
          <ThirdLayerClose parentPath={parentPath} />
        </SwipeMotionDiv>
      </Layout>
    </ThirdLayer>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: { eq: "thirtyYearsPage" }) {
      ourCommitment {
        leadText
        paragraph1
        paragraph2
        paragraph3
      }
      focusOnFiber {
        leadText
        firstBlock {
          paragraph1
          paragraph2
          paragraph3
        }
        secondBlock {
          leadText
          paragraph1
          paragraph2
        }
        imgtxt
      }
      tofuRefuse {
        leadText
        firstBlock {
          paragraph1
          paragraph2
          paragraph3
        }
        secondBlock {
          paragraph1
          paragraph2
        }
        imgtxt
      }
      meetInuria {
        firstBlock {
          paragraph1
          paragraph2
          paragraph3
        }
        leadText
        secondBlock {
          leadText
          paragraph1
          paragraph2
        }
        imgtxt
      }
    }
    lead: componentsYaml {
      laboCard {
        leadText
      }
      bitsOfKnowledgeCard {
        leadText
      }
      thirtyYearsCard {
        leadText
      }
    }
    seo: dataYaml {
      top {
        description
        pass
        title
      }
      page {
        thirtyYers {
          description
          pass
          title
          ourCommitment {
            description
            pass
            title
          }
        }
      }
    }
  }
`

export default OurCommitment
