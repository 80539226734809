/**
 * Third-party libraries
 */
import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import TransitionLink from "gatsby-plugin-transition-link"

/**
 * First-party files
 */
// Libs
import { setSwipeState } from "../lib/setTransitionLinkState"
// Styles
import { media } from "styles/media"
// Components
import { FlexCenter } from "components/CssTools"

const StyledWrap = styled.div`
  width: 100%;
  padding: 16px 16px 34px;
  flex-flow: row nowrap;
  ${FlexCenter}
`
const StyledLink = styled(TransitionLink)`
  cursor: pointer;
  width: 100%;
  ${FlexCenter}
  flex-flow:row nowrap;
  background: #eaeaea;
  border-radius: 30px;
  position: relative;
  height: 40px;
  ${() =>
    media.tablet(css`
      max-width: 400px;
      margin: 0 auto;
    `)}
`

const StyledCross = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  flex-flow: row nowrap;
  ${FlexCenter}
  position:absolute;
  right: 16px;
  span {
    width: 20px;
    height: 2px;
    background: #292929;
    border-radius: 10px;
    position: absolute;
    display: block;
    content: "";
    &:first-child {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }
`

const ThirdLayerClose = props => {
  const { parentPath } = props

  return (
    <StyledWrap>
      <StyledLink
        to={parentPath + "/"}
        exit={setSwipeState(
          typeof window !== "undefined" && window.innerWidth,
          "EXIT",
          "CLOSE"
        )}
        entry={setSwipeState(
          typeof window !== "undefined" && window.innerWidth,
          "ENTRY",
          "CLOSE"
        )}
      >
        <span>close</span>
        <StyledCross>
          <span></span>
          <span></span>
        </StyledCross>
      </StyledLink>
    </StyledWrap>
  )
}

export default ThirdLayerClose
