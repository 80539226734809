import React from "react"
import styled, { css } from "styled-components"
import TransitionLink from "gatsby-plugin-transition-link"

import { setSwipeState } from "../lib/setTransitionLinkState"
import { media } from "../styles/media"
import { FlexCenter } from "../components/CssTools"

const StyledWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 140px;
  margin-top: 22px;
  padding: 0px 16px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  h2 {
    line-height: 1.32em;
    font-weight: 700;
  }
  ${() =>
    media.tablet(css`
      max-width: 800px;
      margin: 0 auto;
    `)}
`
const StyledCross = styled(TransitionLink)`
  cursor: pointer;
  width: 45px;
  height: 45px;
  ${FlexCenter}
  flex-flow:row nowrap;
  position: relative;
  span {
    width: 24px;
    height: 3px;
    background: #292929;
    border-radius: 10px;
    position: absolute;
    display: block;
    content: "";
    &:first-child {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }
`

const ThirdLayerHeader = props => {
  const { parentPath } = props
  return (
    <StyledWrap>
      <h2>{props.text}</h2>
      <StyledCross
        to={parentPath + "/"}
        exit={setSwipeState(
          typeof window !== "undefined" && window.innerWidth,
          "EXIT",
          "CLOSE"
        )}
        entry={setSwipeState(
          typeof window !== "undefined" && window.innerWidth,
          "ENTRY",
          "CLOSE"
        )}
      >
        <span></span>
        <span></span>
      </StyledCross>
    </StyledWrap>
  )
}

export default ThirdLayerHeader
